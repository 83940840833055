.page-switch.page-switch--scroll {
    @media (min-width: 768px) {
        height: 80vh;
        padding-top: 0;
    }
}

.page-switch__item {
    @media screen and (min-width: 768px) {
        height: inherit;
    }
}

@media screen and (min-width: 768px) {
    .page-switch__item--topcard:before {
        background: var(--color-topcard);
    }
}

.page-switch__item__logo {
    z-index: 1;
    width: calc(100rem / 16);
    height: auto;

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-width: calc(216rem / 16);
        transition: opacity 150ms ease-in-out;
    }
}

.page-switch__item:hover .page-switch__item__logo {
    @media screen and (min-width: 768px) {
        opacity: 0;
    }
}