:root {
    --color-primary: #C52E31;
    --color-secondary: #151518;
    --color-primary-light: #d75254;
    --color-primary-dark: #9e2527;
    --color-secondary-light: #2a2a30;
    --color-primary-contrast: var(--color-white);
    --color-dark: #151518;
    --color-text-muted: #BDBDBD;

    --color-text-default: var(--color-white);

    --color-topcard: var(--color-primary);
    --navbar-brand-bg: var(--color-primary);

    --font-default: "Lato", sans-serif;
    --font-default-weight: 400;
    --font-default-black: "Lato Black", sans-serif;
    --font-black-weight: 900;

    --font-size-default: calc(18rem / 16);
}