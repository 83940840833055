.bg-secondary {
    background-color: var(--color-secondary);
}

.bg-secondary-light {
    background-color: var(--color-secondary-light);
}

.text-muted {
    color: var(--color-text-muted) !important;
}