.accordion__header-link,
.accordion__header-link:not(.collapsed) {
    font-family: var(--font-default);
    color: var(--color-white);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.accordion__header-link.collapsed:hover,
.accordion__header-link.collapsed:focus,
.accordion__header-link.collapsed:focus-visible {
    color: var(--color-primary);
}

.accordion__header-link.collapsed .accordion__header-toggle__icon {
    color: var(--color-white);
}
