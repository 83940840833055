.img-text-combination__text-row {
    margin: 0;
    background-color: var(--color-secondary-light);
    padding-top: calc(16rem / 16);
    padding-bottom: calc(16rem / 16);

    @media (min-width: 768px) {
        padding: calc(56rem / 16);
    }
}

.img-text-combination__title {
    font-size: calc(50rem / 16);
    font-family: var(--font-default-black);
    @media (max-width: 767px) {
        font-size: calc(24rem / 16);
    }
}

@media (min-width: 1200px) {
    .img-text-combination__text-col {
        margin-left: 0;
    }

    .img-text-combination__text-col:nth-child(even) {
        margin-left: -16.67%;
    }

    .img-text-combination__text-row {
        padding: calc(112rem / 16);
    }

    .img-text-combination__img-col {
        z-index: 3;
        margin-left: 0;
    }

    .img-text-combination__img-col:nth-child(even) {
        margin-left: -16.67%;
    }
}