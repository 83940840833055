.btn  {
    font-weight: var(--font-black-weight);
    white-space: nowrap;
}

.btn-no-styling:hover {
    color: var(--color-primary);
}

.btn-outline-primary {
    border: 3px solid var(--color-primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    color: var(--color-white);
}

.btn-outline-white {
    background: transparent;
    border: 3px solid var(--color-white);
    color: var(--color-white);
}

.btn-outline-white:hover {
    background: var(--color-white);
    color: var(--color-primary);
}