/* lato-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/topcard/lato-v24-latin-regular.woff2') format('woff2'),
    url('/static/fonts/topcard/lato-v24-latin-regular.woff') format('woff')
}
/* lato-900 - latin */
@font-face {
    font-display: swap;
    font-family: 'Lato Black';
    font-style: normal;
    font-weight: 900;
    src: url('/static/fonts/topcard/lato-v24-latin-900.woff2') format('woff2'),
    url('/static/fonts/topcard/lato-v24-latin-900.woff') format('woff');
}

/* flicker */
@font-face {
    font-display: swap;
    font-family: 'Flicker';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/topcard/flicker-webfont.woff2') format('woff2'),
    url('/static/fonts/topcard/flicker-webfont.woff') format('woff');
}