.topcard-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    @media (max-width: 767px) {
        background-color: var(--color-secondary);
        height: calc(48rem / 16);
    }
}

.topcard-navbar__brand {
    width: calc(128rem / 16);
    height: calc(48rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 calc(8rem / 16);
    background: var(--navbar-brand-bg);
    transition: height 300ms ease-in-out;

    @media (min-width: 768px) {
        width: calc(300rem / 16);
        height: calc(100rem / 16);
        padding: 0 calc(32rem / 16);
    }
}

.topcard-navbar__brand img {
    width: calc(100% - calc(8rem / 16));
    @media (min-width: 768px) {
        width: calc(100% - calc(32rem / 16));
    }
}

@media (min-width: 768px) {
    .topcard-navbar.is-affix .topcard-navbar__brand {
        height: calc(70rem / 16);
    }
}

.topcard-navbar__main-wrapper {
    background-color: unset;
    transition: background-color 300ms ease-in-out;
    width: 100% !important;
    display: flex;
    align-items: center;
    height: calc(48rem / 16);
    font-weight: var(--font-black-weight);

    @media (min-width: 768px) {
        height: calc(70rem / 16);
    }
}

.topcard-navbar.is-affix .topcard-navbar__main-wrapper {
    background-color: var(--color-secondary);
}

.topcard-navbar__main {
    width: 100% !important;
    height: 100% !important;
}


.topcard-navbar__main .topcard-navbar__nav {
    height: 100%;
    display: flex;
    column-gap: calc(36rem / 16);
}

.topcard-navbar__main .topcard-navbar__nav .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
}

.topcard-navbar__main .topcard-navbar__nav .nav-item:after {
    content: '';
    height: calc(4rem / 16);
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary);
    position: absolute;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.topcard-navbar__main .topcard-navbar__nav .nav-item.active:after {
    opacity: 1;
    transform: scaleX(1);
}

.topcard-navbar__main .topcard-navbar__nav .nav-item:hover:after {
    @media screen and (min-width: 768px) {
        opacity: 1;
        transform: scaleX(1);
    }
}

.topcard-navbar__main .topcard-navbar__nav .nav-link {
    padding: 0;
}

.topcard-navbar__right {
    display: flex;
    margin-right: 0;
    margin-left: auto;
    height: 100% !important;

    @media (min-width: 768px) {
        column-gap: calc(40rem / 16);
    }
}

.topcard-navbar__lang-btn {
    white-space: nowrap;
    display: flex;
    align-items: center;
    column-gap: calc(8rem / 16);
}

.navbar__main__btn--primary,
.navbar__main__btn--primary:hover,
.navbar__main__btn--primary:focus {
    color: #fff;
    white-space: nowrap;
}


@media screen and (max-width: 767px) {

    .topcard-navbar.is-open {
        background: var(--color-secondary-light);
    }

    .topcard-navbar__main {
        position: fixed;
        left: 0;
        right: 0;
        top: calc(48rem / 16);
        height: calc(100vh - calc(48rem / 16));
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        background: var(--color-secondary-light);
    }

    .topcard-navbar.is-open .topcard-navbar__main {
        transform: translateX(0);
    }

    .topcard-navbar__main-container {
        height: 100%;
        padding: calc(40rem / 16);
        align-items: start;
    }

    .topcard-navbar__nav {
        flex-direction: column;
    }

    .navbar-toggler__bar {
        background: var(--color-secondary-contrast);
    }

    .topcard-navbar__main .topcard-navbar__nav .nav-link {
        padding: calc(16rem / 16) 0;
    }

}
