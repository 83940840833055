body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    /*font-weight: var(--font-default-weight);*/
}

.font-default-black,
b,
strong,
.strong {
    font-family: var(--font-default-black);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: var(--color-text-default);
    font-family: var(--font-default-black);
}

h2, .h2 {
    text-transform: none;
}

h3, .h3, h4, .h4 {
    font-family: var(--font-default);
    text-transform: none;
}