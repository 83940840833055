.hero {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero__image:after,
.hero__video:after {
    content: '';
    pointer-events: none;
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(21,21,24,0.7) 0%, rgba(21,21,24,0) 10%, rgba(21,21,24,0) 70%, rgba(21,21,24,1) 100%);
}

.hero__image {
    height: 100%;
}
.hero__video {
    position: relative;
}
.hero__video .hero__video__video {
    height: 100vh;
    width: 100%;
}

.hero__content {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.hero__content .font-campaign {
    font-family: "Flicker", sans-serif;
    @media (max-width: 767px) {
        line-height: 1.5;
    }
}

@media (min-width: 1400px) {
    .hero__content .h1.font-campaign{
        font-size: calc(138rem / 16);
    }
}

.countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.countdown__counter {
    background-color: #00000080;
    padding: calc(30rem / 16) calc(60rem / 16) calc(60rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: calc(16rem / 16);
    white-space: normal;
    max-width: 100%;
    @media (max-width: 767px) {
        padding: calc(30rem / 16) calc(24rem / 16) calc(60rem / 16);
    }
}

.countdown__counter-item {
    white-space: nowrap;
}

.countdown__counter-item__number {
    font-family: var(--font-default-black);
    font-size: clamp(1rem, -0.05rem + 5.6vw, 1.875rem);
}

.countdown__cta {
    margin-top: calc(-30rem / 16);
    white-space: normal;
}
