.poi-teaser.poi-teaser--dynamic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 200ms ease-in-out;
}
.poi-teaser.poi-teaser--dynamic:not(.is-active){
    opacity: 0;
    pointer-events: none;
}
.poi-teaser.poi-teaser--dynamic.is-active{
    @media (max-width: 767px) {
        position: fixed;
        height: 100%;
        z-index: 6;
        pointer-events: auto;
        display: flex;
        flex-direction: column;
    }
}

.poi-teaser.poi-teaser--static:not(.is-active){
    opacity: 0;
    pointer-events: none;
}

.poi-teaser__content {
    text-align: center;
    padding: calc(34rem / 16) calc(30rem/16);
    color: var(--color-dark);
    background: #FFFFFF;
    flex-grow: 1;
}

.poi-teaser__title{
    font-size: calc(30rem/16);
    line-height: calc(34/30);
}
.poi-teaser__text{
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    margin-top: calc(8rem/16);
}
.poi-teaser__close{
    position: absolute;
    top: calc(20rem/16);
    right: calc(20rem/16);
    color: var(--color-white);
    cursor: pointer;
    z-index: 1;
    padding: 0 !important;
}
.poi-teaser__close-icon{
    font-size: calc(30rem/16);
}

.poi-teaser__img:after{
    content: '';
    background: #00000088;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.poi-teaser__logo{
    padding: calc(50rem/16);
}

.poi-teaser__action {
    white-space: normal;
}

.poi-map__poi{
    background: none;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-primary-contrast);
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: color 200ms ease-in-out, transform 200ms ease-in-out;
}
.poi-map__poi.is-active{
    /*color: var(--color-primary-contrast);*/
    animation: pulse 2s infinite;
}
.poi-map__poi:hover{
    /*color: var(--color-primary-contrast);*/
    transform: scale(1.2) translate(calc(-40%), calc(-40%));
}
.poi-map__poi:focus{
    outline: none;
}
.poi-map__icon{
    font-size: calc(20rem/16);
    @media (min-width: 768px) {
        font-size: calc(50rem/16);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1) translate(-50%, -50%);
    }
    50% {
        transform: scale(1.2) translate(calc(-40%), calc(-40%));
    }
    100% {
        transform: scale(1) translate(-50%, -50%);
    }
}