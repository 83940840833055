.price-box {
    background-color: var(--color-white);
    padding: calc(40rem / 16);
    color: var(--color-dark);
    max-width: calc(340rem / 16);
}

.price-box__title {
    font-size: calc(22rem / 16);
    /* TODO: --font-default-bold anlegen und hier einsetzen*/
    font-weight: bold;
}

.price-box__icon {
    font-size: calc(60rem / 16);
    color: var(--color-primary);
    margin-bottom: calc(8rem / 16);
    @media (max-width: 767px) {
        font-size: calc(40rem / 16);
    }
}

.price-box__description,
.price-box__notice {
    font-size: calc(18rem / 16);
    line-height: calc(25 / 18);
}