.modal-title {
    color: var(--color-primary);
    font-weight: var(--font-black-weight);
}
.modal-content {
    background-color: var(--color-white);
    color: var(--color-dark);
}

.modal-body__action {
    display: flex;
    justify-content: center;
    -webkit-column-gap: calc(20rem / 16);
    -moz-column-gap: calc(20rem / 16);
    column-gap: calc(20rem / 16);
    margin-top: calc(32rem / 16);

    @media (max-width: 767px) {
        flex-direction: column;
        row-gap: calc(16rem / 16);
        margin-top: calc(16rem / 16);
    }
}

.modal-body__action-link {
    align-content: center;
    white-space: normal;

    @media (min-width: 768px) {
        width: calc(270rem/16);
    }
}