.newsletter {
    background-color: var(--color-primary);
}

.newsletter-redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(60rem / 16);
    width: 100%;
    height: 100%;
    @media (min-width: 992px) {
        aspect-ratio: 1;
        padding: calc(32rem / 16);
    }
}

.newsletter-redirect__title {
    word-break: keep-all;
    font-size: calc(32rem / 16);
    @media (min-width: 992px) {
        font-size: clamp(2rem, -0.769rem + 4.327vw, 3.125rem);
    }

}