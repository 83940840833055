.newsletter-img-teaser {
    position: relative;
    font-size: calc(14rem / 16);
    line-height: calc(20/14);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
        line-height: calc(35/18);
    }
}
.newsletter-img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: .2;
    transition: opacity 200ms ease-in-out;
}
.slick-current .newsletter-img-teaser:after,
.newsletter-img-teaser:hover:after {
    opacity: 0;
}
.newsletter-img-teaser__content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: calc(20rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        max-width: calc(540rem / 16);
        margin: 0 auto;
    }
}

.newsletter-img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.newsletter-img-teaser:hover .newsletter-img-teaser__img {
    transform: scale(1.02);
}