.faq-slide__img-col {
    z-index: 2;
}

.faq-slide__text-col {
    @media (min-width: 992px) {
        margin-left: -8.34%;
    }
}

.faq-slide__text {
    background: var(--color-primary);
    padding: calc(50rem / 16) calc(24rem / 16);
    @media (min-width: 767px) {
        padding: calc(100rem / 16);
    }
    @media (min-width: 992px) {
        padding: calc(270rem / 16) calc(100rem / 16);
    }
}

.faq-slide__text-cta {
    white-space: normal;
}