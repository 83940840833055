.benefits-list {
    display: flex;
    justify-content: center;
    gap: calc(32rem / 16);
    flex-wrap: wrap;

}

.benefits-list__item {
    word-break: keep-all;
    @media (max-width: 767px) {
        flex: 1 1 25%;
    }
}

.benefits-list__lottie-item {
    width: calc(70rem / 16);
    height: calc(70rem / 16);
    margin-bottom: calc(8rem / 16);
    @media (max-width: 767px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
    }
}
